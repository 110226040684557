import React from "react";

const About = () => {
    return (
        <div id="about" className="h-screen bg-[#0a192f] text-gray-300">
            <div className="flex flex-col justify-center items-center h-full w-full">
                <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
                    <div className="sm:text-right pb-8 pl-4">
                        <p className="text-4xl font-bold inline border-b-4 border-pink-600">About</p>
                    </div>
                </div>
                <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
                    <div className="sm:text-right text-4xl font-bold">
                        <p>Hi. I'm Auston, nice to meet you.</p>
                    </div>
                    <div>
                        <p>
                            I am a 27 year old software developer based out of Casper, Wyoming. In my free time, I enjoy exploring the outdoors and taking pictures with my camera and drone.
                        </p>
                    </div>
                </div>
                <div className="carousel carousel-center space-x-4 bg-neutral p-5">
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src={"https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/wr61qvok7mrctpkz9xtw"} className="object-cover rounded-box" alt="photograph"></img>
                    </div>
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src="https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/mq02gykjohluiquznpav" className="object-cover rounded-box" alt="photograph"/>
                    </div>
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src="https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/yyrtakqq06d3yqgneect" className="object-cover rounded-box" alt="photograph"/>
                    </div>
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src="https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/n7pv8lzptvvsozjqu2bn" className="object-cover rounded-box" alt="photograph"/>
                    </div>
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src="https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/z0jhuikcuflssjpi9k8q" className="object-cover rounded-box" alt="photograph"/>
                    </div>
                    <div className="carousel-item max-w-[1000px] w-full max-h-[500px]">
                        <img src="https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/bjbu9qivuavnuzkfsxc4" className="object-cover rounded-box" alt="photograph"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
