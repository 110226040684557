import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Home></Home>
      <About></About>
      <Contact></Contact>
      {/* <Skills></Skills> */}
      {/* <Drone></Drone> */}
    </div>
  );
}

export default App;
