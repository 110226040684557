const Home = () => {
  return (
    <div className='w-full h-screen bg-[#0a192f]'>

      {/* Container */}
      <div id="home" className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <div className="self-center">
          <img src={"https://res.cloudinary.com/dkei027nk/image/upload/f_auto,q_auto/v1/alarson-dev/n2vfil91hrcodkmsevwo"} alt="Logo" className="h-auto rounded-full w-48 md:w-64 mb-5 shadow-lg"></img>
        </div>
        <p className='text-pink-600'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
          Auston Larson
        </h1>
        <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
          I'm a Full Stack Developer.
        </h2>
        <p className='text-[#8892b0] py-4 max-w-[700px]'>
          I have 4+ years of experience in the professional software development industry.
        </p>
        {/* <div>
          <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
            View Work
            <HiArrowNarrowRight className='ml-3 group-hover:rotate-90 duration-300' />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Home;