import React from 'react';
import { AiFillLinkedin } from 'react-icons/ai'; // Ensure you have react-icons installed

const Contact = () => {
    return (
        <div id="contact" className='h-screen bg-[#0a192f] text-gray-300 flex justify-center items-center'>
            <div className='max-w-[1000px] w-full text-center'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
                        Contact Me
                    </p>
                    <p className="py-4">
                        Interested in collaborating or have questions? Feel free to connect with me.
                    </p>
                </div>
                <div className='py-4'>
                    <a href='https://www.linkedin.com/in/auston-larson/' target='_blank' rel='noopener noreferrer' className='text-pink-600 hover:text-pink-800'>
                        <AiFillLinkedin className='inline-block text-5xl'/>
                        <span className='pl-2 text-xl'>LinkedIn Profile</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;
