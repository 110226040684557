import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-scroll';

// https://www.youtube.com/watch?v=2kg0z1qNrkw

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[70px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      {/* <div>
        <img src={Logo} alt="Logo" style={{ width: "50px" }} />
      </div> */}

      <div className="font-bold text-xl">
        ATL
      </div>

      {/* Menu */}
      <div className="hidden md:flex">
        <ul className="flex">
          <li>
            <Link to='home' smooth>Home</Link>
          </li>
          <li>
            <Link to='about' smooth>About</Link>
          </li>
          {/* <li>
            <Link to='skills' smooth>Skills</Link>
          </li>
          <li>
            Work
          </li> */}
          {/* <li>
            <Link to='drone' smooth>Drone Photography</Link>
          </li> */}
          <li>
            <Link to='contact' smooth>Contact</Link>
          </li>
        </ul>
      </div>

      {/* Hamburger Menu */}
      <div onClick={handleClick} className="md:hidden z-10 p-1">
        {nav ? <FaTimes /> : <FaBars />}
      </div>

      {/* Mobile Menu */}
      <div className={!nav ? "hidden" : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"}>
        <ul>
          <li className="py-6 text-4xl"><Link onClick={handleClick} to='home' smooth>Home</Link></li>
          <li className="py-6 text-4xl"><Link onClick={handleClick} to='about' smooth>About</Link></li>
          {/* <li className="py-6 text-4xl">Skills</li> */}
          {/* <li className="py-6 text-4xl">Work</li> */}
          {/* <li className="py-6 text-4xl"><Link onClick={handleClick} to='drone' smooth>Drone Photography</Link></li> */}
          <li className="py-6 text-4xl"><Link onClick={handleClick} to='contact' smooth>Contact</Link></li>
        </ul>
      </div>

      {/* Social Icons */}
    </div>
  );
};

export default Navbar;
